import * as _ from 'lodash';
import { Server } from "../Constants/config";
import { attemptMyApiRequest } from '../helpers/apiRequest';
import { DateTime } from "luxon";

// ===================== AUTHENTICATION

export function userLoginRequest(formData) {

    const url = new URL('/authentication/4.0/sessions', Server.backendURL);
    return fetch(url, {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'POST',
        credentials: "include",
        body: JSON.stringify(formData)
    })

}

export function userLogoutRequest() {


    const url = new URL('/authentication/4.0/current-session', Server.backendURL);
    return fetch(url, {
        method: 'DELETE',
        credentials: "include",
    })
}

export function userRegisterRequest(formData) {

    const url = new URL('/api/5.0/users', Server.backendURL);
    return fetch(url, {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(formData)
    })

}

export function userForgotPasswordRequest(formData) {

    const url = new URL('/api/4.0/account-recovery-requests', Server.backendURL);
    return fetch(url, {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(formData)
    })

}

export function userResetPasswordRequest(formData) {

    const url = new URL('/api/4.0/password-resets', Server.backendURL);
    return fetch(url, {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(formData)
    })

}

// ===================== USER DATA

export function userDataRequest() {

    const url = new URL('/api/5.0/session/user', Server.backendURL);
    url.search = new URLSearchParams({
        $limit: JSON.stringify(1),
        $projection: JSON.stringify([
            'accountant',
            'admin',
            'assuredNetworkMember',
            'companyAddress',
            'companyCity',
            'companyCountry',
            'companyName',
            'companyPhoneNumber',
            'companyPostalCode',
            'contact',
            'contractExpirationType_id',
            'displayName',
            'emailAddress',
            'id',
            'mobilePhoneNumber'
        ]),
    })

    return attemptMyApiRequest(url, {
        method: 'GET',
    })

}

// UPDATE SESSION USER
export function updateUserRequest(formData, userId = false) {

    const endpoint = userId ? `/api/5.0/users/${userId}` : '/api/5.0/session/user';

    const url = new URL(endpoint, Server.backendURL);

    return attemptMyApiRequest(url, {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'PATCH',
        body: JSON.stringify(formData),
    })

}

// ===================== CARGO CERTIFICATES PAGE

/**
 * Parameters -> Promise Response
 * Parameters = {
 *  certificateId               : Null | Integer
 *  filterCurrency              : Null | Integer
 *  filterFromDate              : Null | Date
 *  filterNetworkAffiliation    : Null | Integer
 *  filterToDate                : Null | Date
 *  limit                       : Null | Integer
 *  order                       : Null | Iterable (String, Direction)
 *  skip                        : Null | Integer
 * }
 *  Direction = Ascending | Descending
 *      Ascending   = 1
 *      Descending  = -1
 * @param properties.limit Null | Integer
 * @param properties.order Null | Iterable (String, Direction)
 * @param properties.skip Null | Integer
 * @return Promise Response
 */
export function cargoCertificatesDataRequest(properties) {

    const {
        filterNetworkAffiliation,
        filterCurrency,
        filterFromDate,
        filterToDate,
        certificateId,
        limit,
        order,
        skip
    } = properties;

    const url = new URL('/api/4.0/contracts', Server.backendURL);
    url.search = new URLSearchParams({
        $projection: JSON.stringify([
            'containerIdentifier',
            'contractor_id',
            'creationTime',
            'currency_id',
            'departureDate',
            'description',
            'destinationCity',
            'destinationContinent_id',
            'destinationCountry',
            'draft',
            'exported',
            'id',
            'insuranceCertificateCosts',
            'minimumPremiumCosts',
            'networkAffiliation_id',
            'nameAssured',
            'reference',
            'sourceCity',
            'sourceContinent_id',
            'sourceCountry',
            'transportIdentifier',
            'type_id',
            'value'
        ])
    })

    if (typeof (certificateId) !== "undefined" && certificateId !== "") {
        url.searchParams.set('id', certificateId)
    }

    if (typeof (filterNetworkAffiliation) !== "undefined" && filterNetworkAffiliation !== "") {
        url.searchParams.set('networkAffiliation_id', filterNetworkAffiliation)
    }

    if (typeof (filterCurrency) !== "undefined" && filterCurrency !== "") {
        url.searchParams.set('currency_id', filterCurrency)
    }

    if (filterToDate || filterFromDate) {
        url.searchParams.set('departureDate', JSON.stringify({
            ...(filterToDate) && { $lte: DateTime.fromJSDate(filterToDate).toISO() },
            ...(filterFromDate) && { $gte: DateTime.fromJSDate(filterFromDate).toISO() },
        }))
    }

    if (limit == null || limit === Infinity) {
        url.searchParams.set('$limit', 'Infinity')
    } else {
        url.searchParams.set('$limit', limit)
    }

    if (order != null)
        url.searchParams.set('$order', JSON.stringify(Array.from(order)))

    if (skip != null)
        url.searchParams.set('$skip', skip)

    return attemptMyApiRequest(url, {
        method: 'GET',
    })

}

export function removeCertificateRequest(certificateId) {

    const url = new URL(`/api/4.0/contracts/${certificateId}`, Server.backendURL);

    return attemptMyApiRequest(url, {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'DELETE'
    })

}

// ===================== USERS PAGE

/**
 * Null | Parameters -> Promise Response
 * Parameters = {
 *  filter  : Null | Object *
 *  limit   : Null | Integer
 *  order   : Null | Iterable (String, Direction)
 *  skip    : Null | Integer
 * }
 *  Direction = Ascending | Descending
 *      Ascending   = 1
 *      Descending  = -1
 * @param parameters.filter Null | Object *
 * @param parameters.limit Null | Integer
 * @param parameters.order Null | Iterable (String, Direction)
 * @param parameters.skip Null | Integer
 * @return Promise Response
 */
export function usersDataRequest(parameters = {}) {

    const { filter }    = parameters
    const { limit }     = parameters
    const { order }     = parameters
    const { skip }      = parameters

    const url = new URL('/api/5.0/users', Server.backendURL);
    url.search = new URLSearchParams({
        ... _.mapValues(filter ?? {}, JSON.stringify),
        $projection: JSON.stringify([
            'accountant',
            'active',
            'assuredNetworkMember',
            'billable',
            'companyAddress',
            'companyCity',
            'companyCountry',
            'companyName',
            'companyPhoneNumber',
            'companyPostalCode',
            'contact',
            'contractExpirationType_id',
            'displayName',
            'emailAddress',
            'id',
            'mobilePhoneNumber',
            'networkAffiliation_id'
        ]),
    })

    if (limit == null || limit === Infinity) {
        url.searchParams.set('$limit', 'Infinity')
    } else {
        url.searchParams.set('$limit', limit)
    }

    if (order != null)
        url.searchParams.set('$order', JSON.stringify(Array.from(order)))

    if (skip != null)
        url.searchParams.set('$skip', skip)

    return attemptMyApiRequest(url, {
        method: 'GET',
    })

}

export function removeUserRequest(userId) {
    const url = new URL(`/api/4.0/users/${userId}`, Server.backendURL);

    return attemptMyApiRequest(url, {
        method: 'DELETE',
    })
}

// ===================== EDIT USERS PAGE

export function networksDataRequest() {

    const url = new URL('/api/4.0/networks', Server.backendURL);
    url.search = new URLSearchParams({
        $limit: 'Infinity',
        $projection: JSON.stringify([
            'certificateTitle',
            'contractConditions',
            'homePageContent',
            'id',
            'insuranceNumber',
            'logoFilepath',
            'name'
        ]),
    })

    return attemptMyApiRequest(url, {
        method: 'GET',
    })

}

/**
 * Null | Parameters -> Promise Response
 * Parameters = {
 *  filter:     Null | Object *
 *  limit:      Null | Integer
 *  projection: Null | Array String
 *  skip:       Null | Integer
 * }
 * @param parameters.filter Null | Object *
 * @param parameters.limit Null | Integer
 * @param parameters.projection Null | Array String
 * @param parameters.skip Null | Integer
 * @return Promise Response
 */
export function contractTypesDataRequest(parameters = {}) {

    const { filter }                        = parameters
    const { limit = Infinity }              = parameters
    const { projection = ['id', 'label'] }  = parameters
    const { skip }                          = parameters

    const url = new URL('/api/4.0/contract-types', Server.backendURL)

    if (filter != null) {
        for (const [key, value] of Object.entries(filter))
            url.searchParams.set(key, JSON.stringify(value))
    }

    if (limit != null) {
        if (limit === Infinity) {
            url.searchParams.set('$limit', 'Infinity')
        } else {
            url.searchParams.set('$limit', limit)
        }
    }

    if (projection != null)
        url.searchParams.set('$projection', JSON.stringify(projection))

    if (skip != null)
        url.searchParams.set('$skip', skip)

    return attemptMyApiRequest(url, {
        method: 'GET',
    })
}

// ===================== CREATE / EDIT CARGO CERTIFICATES PAGE

export function createCargoCertificateRequest(formData) {

    const url = new URL('/api/4.0/contracts', Server.backendURL);

    return attemptMyApiRequest(url, {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(formData)
    })

}

export function editCargoCertificateRequest(formData, certificateId) {

    const url = new URL(`/api/4.0/contracts/${certificateId}`, Server.backendURL);

    return attemptMyApiRequest(url, {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'PATCH',
        body: JSON.stringify(formData)
    })

}


// ===================== DOWNLOADS

export function downloadCertificate(contractId) {

    const url = new URL(`/api/4.0/contracts/${contractId}/insurance-certificate.pdf`, Server.backendURL);

    return attemptMyApiRequest(url, {
        method: 'GET',
    })

}

export function downloadBookingSlip(contractId) {

    const url = new URL(`/api/4.0/contracts/${contractId}/booking-slip.pdf`, Server.backendURL);

    return attemptMyApiRequest(url, {
        method: 'GET',
    })

}

export function downloadCertificatesXLS(properties) {

    const {
        filterNetworkAffiliation,
        filterCurrency,
        filterFromDate,
        filterToDate
    } = properties;

    const url = new URL('/api/4.0/contracts.xlsx', Server.backendURL);

    url.searchParams.set('$limit', 'Infinity')

    url.searchParams.set('$order', JSON.stringify([
        ['departureDate', -1]
    ]))

    if (filterNetworkAffiliation !== "") {
        url.searchParams.set('networkAffiliationId', filterNetworkAffiliation)
    }

    if (filterCurrency !== "") {
        url.searchParams.set('currencyId', filterCurrency)
    }

    if (filterToDate || filterFromDate) {
        url.searchParams.set('departureDate', JSON.stringify({
            ...(filterToDate) && { $lte: DateTime.fromJSDate(filterToDate).toISO() },
            ...(filterFromDate) && { $gte: DateTime.fromJSDate(filterFromDate).toISO() },
        }))
    }

    return attemptMyApiRequest(url, {
        method: 'GET',
    })

}

// ===================== Exclude countries page

/**
 * Parameters -> Promise Response
 * Parameters = {
 *  filter  : Null | Object *
 *  limit   : Null | Integer
 *  order   : Null | Iterable (String, Direction)
 *  skip    : Null | Integer
 * }
 *  Direction = Ascending | Descending
 *      Ascending   = 1
 *      Descending  = -1
 * @param parameters.filter Null | Object *
 * @param parameters.limit Null | Integer
 * @param parameters.order Null | Iterable (String, Direction)
 * @param parameters.skip Null | Integer
 * @return Promise Response
 */
export function exemptCountriesDataRequest(parameters) {

    const { filter }    = parameters
    const { limit }     = parameters
    const { order }     = parameters
    const { skip }      = parameters

    const url = new URL('/api/4.0/exempt-countries', Server.backendURL);

    if (filter != null) {
        for (const [key, value] of Object.entries(filter))
            url.searchParams.set(key, JSON.stringify(value))
    }

    if (limit != null) {
        if (limit === Infinity) {
            url.searchParams.set('$limit', 'Infinity')
        } else {
            url.searchParams.set('$limit', limit)
        }
    }

    if (order != null)
        url.searchParams.set('$order', JSON.stringify(Array.from(order)))

    if (skip != null)
        url.searchParams.set('$skip', skip)

    return attemptMyApiRequest(url, {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'GET',
    })

}

export function addExemptCountryRequest(selectedCountry) {

    const url = new URL('/api/4.0/exempt-countries', Server.backendURL);

    return attemptMyApiRequest(url, {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
            "countryCode": selectedCountry
        })
    })

}

export function removeExemptCountryRequest(selectedCountry) {

    const url = new URL(`/api/4.0/exempt-countries/${selectedCountry}`, Server.backendURL);

    return attemptMyApiRequest(url, {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'DELETE'
    })

}

// ===================== Network Affiliations

/**
 * Parameters -> Promise Response
 * Parameters = {
 *  projection: Array String
 * }
 * @param parameters.projection Array String
 * @return Promise Response
 */
export async function getCurrentSessionNetwork(parameters) {

    const { projection } = parameters

    const url = new URL('/api/4.0/session/network', Server.backendURL);

    url.searchParams.set('$projection', JSON.stringify(projection))

    return await attemptMyApiRequest(url);

}

/**
 * Parameters -> Promise Response
 * Parameters = {
 *  filter  : Null | Object *
 *  limit   : Null | Integer
 *  order   : Null | Iterable (String, Direction)
 *  skip    : Null | Integer
 * }
 *  Direction = Ascending | Descending
 *      Ascending   = 1
 *      Descending  = -1
 * @param parameters.filter Null | Object *
 * @param parameters.limit Null | Integer
 * @param parameters.order Null | Iterable (String, Direction)
 * @param parameters.skip Null | Integer
 * @return Promise Response
 */
export function networkAffiliationsDataRequest(parameters) {

    const { filter }    = parameters
    const { limit }     = parameters
    const { order }     = parameters
    const { skip }      = parameters

    const url = new URL('/api/4.0/networks', Server.backendURL);
    url.search = new URLSearchParams({
        ... _.mapValues(filter ?? {}, JSON.stringify),
        $projection: JSON.stringify([
            'certificateTitle',
            'contractConditions',
            'homePageContent',
            'id',
            'insuranceNumber',
            'logoFilepath',
            'name',
            'claimsPayableProcedure'
        ]),
    })

    if (limit != null) {
        if (limit === Infinity) {
            url.searchParams.set('$limit', 'Infinity')
        } else {
            url.searchParams.set('$limit', limit)
        }
    }

    if (order != null)
        url.searchParams.set('$order', JSON.stringify(Array.from(order)))

    if (skip != null)
        url.searchParams.set('$skip', skip)

    return attemptMyApiRequest(url, {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'GET',
    })

}

export function networkAffiliationsCurrenciesDataRequest(affiliationId) {

    const url = new URL(`/api/4.2/networks/${affiliationId}/currencies`, Server.backendURL);
    url.search = new URLSearchParams({
        $limit: 'Infinity',
        $projection: JSON.stringify([
            'code',
            'id',
            'maximumContractValue',
            'name',
            'symbol'
        ]),
    })

    return attemptMyApiRequest(url, {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'GET',
    })

}

export function networkAffiliationsMinimumPremiumsDataRequest(affiliationId) {

    const url = new URL(`/api/4.0/networks/${affiliationId}/minimum-premiums`, Server.backendURL);
    url.search = new URLSearchParams({
        $limit: 'Infinity',
        $projection: JSON.stringify([
            'code',
            'id',
            'name',
            'symbol'
        ]),
    })

    return attemptMyApiRequest(url, {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'GET',
    })

}

export function networkCertificatePriceDataRequest(affiliationId) {

    const url = new URL(`/api/4.0/networks/${affiliationId}/certificate-prices`, Server.backendURL);
    url.search = new URLSearchParams({
        $limit: 'Infinity',
        $projection: JSON.stringify([
            'currency_id',
            'value'
        ]),
    })

    return attemptMyApiRequest(url, {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'GET',
    })

}

export function networkInterestRatesDataRequest(affiliationId, session = false) {

    let urlPath = `/api/4.0/networks/${affiliationId}/interest-rates`;

    if (session) {
        urlPath = '/api/4.0/session/interest-rates';
    }

    const url = new URL(urlPath, Server.backendURL);
    url.search = new URLSearchParams({
        $limit: 'Infinity',
        $projection: JSON.stringify([
            'destinationContinent_id',
            'merchandiseType_id',
            'sourceContinent_id',
            'valuePerMillion'
        ]),
    })

    return attemptMyApiRequest(url, {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'GET',
    })

}

// ===================== CREATE / EDIT Network Affiliations

export function createNetworksDataRequest(formData) {

    const url = new URL(`/api/4.2/networks`, Server.backendURL);

    return attemptMyApiRequest(url, {
        method: 'POST',
        body: formData
    })

}

export function editNetworksDataRequest(formData, networkId) {

    const url = new URL(`/api/4.2/networks/${networkId}`, Server.backendURL);

    return attemptMyApiRequest(url, {
        method: 'POST',
        body: formData
    })

}

export function merchandiseTypesDataRequest() {

    const url = new URL('/api/4.0/merchandise-types', Server.backendURL);
    url.search = new URLSearchParams({
        $limit: 'Infinity',
        $projection: JSON.stringify([
            'id',
            'value'
        ]),
    })

    return attemptMyApiRequest(url, {
        method: 'GET',
    })

}

export function currenciesDataRequest() {

    const url = new URL('/api/4.0/currencies', Server.backendURL);
    url.search = new URLSearchParams({
        $limit: 'Infinity',
        $projection: JSON.stringify([
            'code',
            'id',
            'name',
            'symbol'
        ]),
    })

    return attemptMyApiRequest(url, {
        method: 'GET',
    })

}

/**
 * Parameters -> Promise Response
 * Parameters = {
 *  filter:     Null | Object *
 *  limit:      Null | Integer
 *  projection: Array String
 *  skip:       Null | Integer
 * }
 * @param parameters.filter Null | Object *
 * @param parameters.limit Null | Integer
 * @param parameters.projection Array String
 * @param parameters.skip Null | Integer
 * @return Promise Response
 */
export async function getCurrentSessionCurrencies(parameters) {

    const { filter = {} }           = parameters
    const { limit = 'Infinity' }    = parameters
    const { projection }            = parameters
    const { skip }                  = parameters

    const url = new URL('/api/4.0/session/currencies', Server.backendURL)

    if (filter != null) {
        for (const [key, value] of Object.entries(filter))
            url.searchParams.set(key, JSON.stringify(value))
    }

    if (limit === Infinity) {
        url.searchParams.set('$limit', 'Infinity')
    } else {
        url.searchParams.set('$limit', limit)
    }

    url.searchParams.set('$projection', JSON.stringify(projection))

    if (skip != null)
        url.searchParams.set('$skip', skip)

    return await attemptMyApiRequest(url);

}

export async function getCurrentSessionMinimumPremiums() {

    const url = new URL('/api/4.0/session/minimum-premiums', Server.backendURL);
    url.search = new URLSearchParams({
        $limit: 'Infinity',
        $projection: JSON.stringify([
            'currency_id',
            'value'
        ]),
    })

    return await attemptMyApiRequest(url);

}

export async function getCurrentSessionCertificatePrices() {

    const url = new URL('/api/4.0/session/certificate-prices', Server.backendURL);
    url.search = new URLSearchParams({
        $limit: 'Infinity',
        $projection: JSON.stringify([
            'currency_id',
            'value'
        ]),
    })

    return await attemptMyApiRequest(url);

}

// ===================== Continents

export function continentsDataRequest() {

    const url = new URL('/api/4.0/continents', Server.backendURL);
    url.search = new URLSearchParams({
        $limit: 'Infinity',
        $projection: JSON.stringify([
            'id',
            'name',
        ]),
    })

    return attemptMyApiRequest(url, {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'GET',
    })

}
